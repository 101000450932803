import { Stack } from "@fluentui/react";
import styles from "./Chat.module.css";
import { LoadingImg } from "../../../components/CarbonAIChat/common/Common";
import { PreDefinedQuestions } from "./PreDefinedQuestions";

const NoChat = ({ onCardClick }: { onCardClick: (prompt: string) => void }) => {
  return (
    <Stack className={styles.chatEmptyState}>
      <div className={styles.chatEmptyState}>
        <LoadingImg />
        <p className={styles.howcanhelp}>Hi, how can I help you today?</p>
      </div>
      <PreDefinedQuestions onCardClick={onCardClick} />
    </Stack>
  );
};

export default NoChat;
