import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import "./index.css";
import Chat from "./pages/CarbonAIChat/layout/Layout";
import CWS from "./pages/CWS/layout/Layout";
import {
  AppStateContext,
  AppStateProvider,
} from "./state/CarbonAIChat/AppProvider";
import useReportingHubAuthentication from "./components/CarbonAIChat/ReportingHub/Landing";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./msalconfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { AuthMode } from "./api/models";
import { logUserLoginDetails } from "./api";
import { UserLoginDetails } from "./api/models";

export const msalInstance = new PublicClientApplication(msalConfig);

initializeIcons();

const shouldUseRH = () => {
  // Logic to determine if RH flow should be used
  return (
    window.location.search.includes("isReportingHub=true") ||
    window.location.search.includes("?code")
  );
};

const AuthenticationBootstrap = () => {
  const [isRHEnabled, setisRHEnabled] = useState(false);
  const appStateContext = useContext(AppStateContext); // Assign appStateContext
  const [isExternalUser, setisExternalUser] = useState(false); // Assign isExternalUser

  // customed hook to handle ReportingHub authentication
  useReportingHubAuthentication(isRHEnabled);

  // Function to handle the response from MSAL
  async function handleResponse(response: any) {
    if (response === null) {
      msalInstance.loginRedirect(loginRequest); // not logged in
    } else {
      // set the active account
      msalInstance.setActiveAccount(response.account);
      const activeAccount = msalInstance.getActiveAccount();
      const accounts = msalInstance.getAllAccounts();
      const user = activeAccount || accounts[0];

      if (
        user.username &&
        user.username !== "undefined" &&
        user.username !== ""
      ) {
        // check if the user is an external user and set the flag such that it won't be able to access the chat
        if (!user.username.includes("@starcb.com")) {
          setisExternalUser(true);
        } else {
          // log the user login details
          if (sessionStorage.getItem("msalUserEmail") !== user.username) {
            // log the user login details
            const userLoginDetails: UserLoginDetails = {
              user_email: user.username,
              user_name: user.name || "",
              provider: "MSAL",
            };

            await logUserLoginDetails(userLoginDetails);
          }
          sessionStorage.setItem("msalUserEmail", user.username);
          if (user.name && user.name !== "undefined" && user.name !== "") {
            sessionStorage.setItem("msalUserName", user.name);
          }

          appStateContext?.dispatch({
            type: "SET_IS_AUTHENTICATED",
            payload: true,
          });
          appStateContext?.dispatch({
            type: "SET_AUTH_MODE",
            payload: AuthMode.MSAL,
          });
        }
      }
    }
  }

  useEffect(() => {
    const useRH = shouldUseRH();
    setisRHEnabled(useRH);

    if (useRH) {
      // Perform ReportingHub authentication
      sessionStorage.setItem("isReportingHub", "true");
      sessionStorage.removeItem("msalUserEmail");
      sessionStorage.removeItem("msalUserName");
      appStateContext?.dispatch({
        type: "SET_AUTH_MODE",
        payload: AuthMode.ReportingHub,
      });
    } else {
      // Perform MSAL authentication
      sessionStorage.removeItem("isReportingHub");
      sessionStorage.removeItem("RHUserName");
      sessionStorage.removeItem("RHUserEmail");
      (async () => {
        msalInstance.initialize().then(async () => {
          // If the user is on localhost, dont do msal authentication
          if (window.location.hostname != "127.0.0.1") {
            const tokenResponse = await msalInstance.handleRedirectPromise();
            handleResponse(tokenResponse);
          } else {
            appStateContext?.dispatch({
              type: "SET_IS_AUTHENTICATED",
              payload: true,
            });
            appStateContext?.dispatch({
              type: "SET_AUTH_MODE",
              payload: AuthMode.MSAL,
            });
          }
        });
      })();
    }
  }, []);

  return isRHEnabled && appStateContext?.state.isAuthenticated ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path="/cws" element={<CWS />} />
      </Routes>
    </BrowserRouter>
  ) : appStateContext?.state.isAuthenticated &&
    window.location.hostname != "127.0.0.1" &&
    !isExternalUser ? (
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Chat />} />
          <Route path="/cws" element={<CWS />} />
        </Routes>
      </BrowserRouter>
    </MsalProvider>
  ) : window.location.hostname == "127.0.0.1" ? (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path="/cws" element={<CWS />} />
      </Routes>
    </BrowserRouter>
  ) : null;
};

export default function App() {
  return (
    <AppStateProvider>
      <AuthenticationBootstrap />
    </AppStateProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
