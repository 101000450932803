import Header from "../header/Header";
import Cws from "../cws/Cws";
import { useEffect, useState } from "react";
import NoAccess from "../No Access/NoAccess";
import { getUploadAcess } from "../../../api";
import { Loader } from "../../../components/CarbonAIChat/common/Common";

const Layout = () => {
  const [uploadAccess, setUploadAccess] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);

  const fetchData = () => {
    getUploadAcess()
      .then((response) => response.json())
      .then((jsonData) => {
        setUploadAccess(true);
        setLoader(false);
      })
      .catch((error) => {
        setUploadAccess(false);
        setLoader(false);
      });
  };
  useEffect(() => {
    if (location.host === "127.0.0.1:5000") {
      setUploadAccess(true);
      setLoader(false);
      return;
    }
    fetchData();
  }, []);

  return (
    <div>
      <Header />
      {loader ? <Loader /> : uploadAccess ? <Cws /> : <NoAccess />}
    </div>
  );
};

export default Layout;
