import React from "react";
import { Spinner } from "react-bootstrap";
import avatar from "../../../assets/Avatar.svg";

export function Loader() {
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center align-self-center loaderDiv">
        <Spinner animation="grow" variant="light" className="custom-spinner" />
      </div>
    </div>
  );
}

export function LoadingImg() {
  return (
    <div>
      <img src={avatar} alt="avatar" className="avatar" />
    </div>
  );
}

export function UTCToLocaleDateTime(utcDateTime: string): {
  date: string;
  time: string;
} {
  const dateObject = new Date(utcDateTime);
  const currentDate = new Date();
  // Extracting Date Parts
  const year = dateObject.getFullYear();
  const monthIndex = dateObject.getMonth(); // getMonth() returns 0-11
  const day = dateObject.getDate();

  // Array of month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extracting Time Parts and converting to 12-hour format
  let hour = dateObject.getHours();
  const minute = dateObject.getMinutes();
  const ampm = hour >= 12 ? "PM" : "AM";

  hour = hour % 12;
  hour = hour ? hour : 12; // the hour '0' should be '12'

  // Formatting to ensure two digits
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;

  // Creating formatted strings
  const date =
    dateObject.getFullYear() === currentDate.getFullYear()
      ? dateObject.getMonth() === currentDate.getMonth() &&
        dateObject.getDate() === currentDate.getDate()
        ? "Today"
        : `${months[monthIndex]} ${formattedDay}`
      : `${months[monthIndex]} ${formattedDay}, ${year}`;
  const time = `${formattedHour}:${formattedMinute} ${ampm}`;

  // Returning the result as an object
  return { date, time };
}

export function isValidUrl(urlString: string) {
  try {
    new URL(urlString);
    return true;
  } catch (err) {
    return false;
  }
}
