import styles from "./NoAccess.module.css"; // Import your CSS module or stylesheet

const NoAccess = () => {
  return (
    <div className={styles.noAccessContainer}>
      <h1>No Access</h1>
      <p>
        You do not have permission to view this page. Please contact your
        administrator for more information.
      </p>
    </div>
  );
};

export default NoAccess;
