import { AskResponse, Citation } from "../../../api";
import { cloneDeep } from "lodash-es";

type ParsedAnswer = {
  citations: Citation[];
  markdownFormatText: string;
};

export function parseAnswer(answer: AskResponse): ParsedAnswer {
  let answerText = answer.answer;
  const citationLinks = answerText.match(/\[(doc\d\d?\d?)]/g);

  const lengthDocN = "[doc".length;

  let filteredCitations = [] as Citation[];
  let citationReindex = 0;
  let count = 0;
  citationLinks?.forEach((link) => {
    // Replacing the links/citations with number
    let citationIndex = link.slice(lengthDocN, link.length - 1);
    let citation = cloneDeep(
      answer.citations[Number(citationIndex) - 1]
    ) as Citation;
    if (!filteredCitations.find((c) => c.id === citationIndex) && citation) {
      // Convert the citation object to a JSON string
      let citationString = JSON.stringify(citation);
      // Replace special characters which are not valid in HTML attributes
      citationString = citationString.replace(/"/g, "&quot;");
      citation.id = citationIndex; // original doc index to de-dupe
      citation.reindex_id = citationReindex.toString(); // reindex from 1 for display
      count = count + 1;
      // answerText = answerText.replaceAll(
      //   link,
      //   ` [<span data-set="${citationString} " class="custom-button" data-index="${citationIndex}" data-count="${count}"></span>]`
      // );
      answerText = answerText.replaceAll(link, ``);

      filteredCitations.push(citation);
    }
  });

  return {
    citations: filteredCitations,
    markdownFormatText: answerText,
    // markdownFormatText: answerText+'[Google](https://www.google.com)'
  };
}
