import { IconButton, Stack } from "@fluentui/react";
import styles from "./Chat.module.css";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import React, { useContext } from "react"; // Ensure React is imported when using JSX
import { Citation } from "../../../api/models";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import { CitationLink } from "../../../components/CarbonAIChat/common/Button";

interface CitationPaneProps {
  activeCitation: Citation;
  setIsCitationPanelOpen: (val: boolean) => void; // Use lowercase boolean
}

const CitationPanel: React.FC<CitationPaneProps> = ({
  activeCitation,
  setIsCitationPanelOpen,
}) => {
  const appStateContext = useContext(AppStateContext);

  return (
    <section style={{ width: "100%" }}>
      <Stack.Item
        className={styles.citationPanel}
        tabIndex={0}
        role="tabpanel"
        aria-label="Citations Panel"
      >
        <Stack
          aria-label="Citations Panel Header Container"
          horizontal
          className={styles.citationPanelHeaderContainer}
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <span aria-label="Citations" className={styles.citationPanelHeader}>
            Citations
          </span>
          <IconButton
            iconProps={{ iconName: "Cancel" }}
            aria-label="Close citations panel"
            onClick={() => {
              setIsCitationPanelOpen(false);
              if (
                appStateContext?.state.isSmallScreen &&
                appStateContext?.state.activeStack === "citation"
              )
                appStateContext.dispatch({
                  type: "SET_ACTIVE_STACK",
                  payload: "chat",
                });
            }}
            styles={{
              root: {
                color: "var(--secondary)",
              },
            }}
          />
        </Stack>
        {activeCitation.documenturl && (
          <button
            type="button"
            onClick={() => {
              if (activeCitation.documenturl) {
                window.open(activeCitation.documenturl, "_blank");
              }
            }}
            style={{
              background: "none",
              color: "blue",
              border: "none",
              padding: "0",
              cursor: "pointer",
              font: "inherit",
              textAlign: "left",
              wordBreak: "break-all",
            }}
          >
            Official website <CitationLink />
          </button>
        )}

        <h5
          className={styles.citationPanelTitle}
          tabIndex={0}
          title={activeCitation.title ? activeCitation.title : ""}
        >
          {activeCitation.title}
        </h5>
        <div className="badge bg-secondary">
          Images translated may not be readable.
        </div>
        <div tabIndex={0}>
          <ReactMarkdown
            linkTarget="_blank"
            className={styles.citationPanelContent}
            children={activeCitation.content} // Prefer using children as a prop explicitly when possible
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </Stack.Item>
    </section>
  );
};

export default CitationPanel;
