import { Stack } from "@fluentui/react";
import styles from "./Chat.module.css";
import { Answer } from "../../../components/CarbonAIChat/Answer";
import Avatar from "../../../assets/Avatar-Conv.jpg";
interface LoaderProps {
  analyzing: boolean;
}
export default function ChatLoader({ analyzing }: LoaderProps) {
  return (
    <div className={styles.chatMessageGpt}>
      <div className={styles.aIMsg}>
        <div className={`circle ${styles.loaderInitial}`}>
          <img
            alt="avtar"
            src={Avatar}
            className={`${styles.aiIcon}`}
            aria-hidden="true"
          />
        </div>
        <div className={styles.aIMsgDiv}>
          <div className="colorSecondary marginBottom5">CarbonAI </div>
          <Answer
            answer={{
              source: "",
              answer: analyzing ? "Analyzing Query..." : "Generating answer...",
              citations: [],
            }}
            onCitationClicked={() => null}
            isLoading={true}
            isLast={true}
            reGenAnswer={() => null}
            oldDetails={{
              oldQuestion: "",
              questionId: "",
            }}
          />
        </div>
      </div>
    </div>
  );
}
