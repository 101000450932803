import {
  CommandBarButton,
  ContextualMenu,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  ICommandBarStyles,
  IContextualMenuItem,
  IStackStyles,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";

import styles from "./ChatHistoryPanel.module.css";
import { useContext } from "react";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import React from "react";
import ChatHistoryList from "./ChatHistoryList";
import { ChatHistoryLoadingState, historyDeleteAll } from "../../../api";
import { BackIcon, SideBarClose } from "../common/Button";

interface ChatHistoryPanelProps {
  disabledButton: () => boolean;
  newChat: () => void;
  setDefaultQues: (val: string) => void;
}

export enum ChatHistoryPanelTabs {
  History = "History",
}

const commandBarStyle: ICommandBarStyles = {
  root: {
    padding: "0",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
};

const commandBarButtonStyle: Partial<IStackStyles> = {
  root: { height: "50px" },
};

export function ChatHistoryPanel({
  newChat,
  disabledButton,
  setDefaultQues,
}: ChatHistoryPanelProps) {
  const appStateContext = useContext(AppStateContext);
  const chatHistory = appStateContext?.state.chatHistory;

  const [showContextualMenu, setShowContextualMenu] = React.useState(false);
  const [hideClearAllDialog, { toggle: toggleClearAllDialog }] =
    useBoolean(true);
  const [clearing, setClearing] = React.useState(false);
  const [clearingError, setClearingError] = React.useState(false);

  const clearAllDialogContentProps = {
    type: DialogType.close,
    title: !clearingError
      ? "Are you sure you want to clear all chat history?"
      : "Error deleting all of chat history",
    closeButtonAriaLabel: "Close",
    subText: !clearingError
      ? "All chat history will be permanently removed."
      : "Please try again. If the problem persists, please contact the site administrator.",
  };

  const modalProps = {
    titleAriaId: "labelId",
    subtitleAriaId: "subTextId",
    isBlocking: true,
    styles: { main: { maxWidth: 450 } },
  };

  const menuItems: IContextualMenuItem[] = [
    {
      key: "clearAll",
      text: "Clear all chat history",
      iconProps: {
        iconName: "Delete",
        styles: { root: { color: "var(--secondary)" } },
      },
    },
  ];

  const handleHistoryClick = () => {
    appStateContext?.dispatch({ type: "TOGGLE_CHAT_HISTORY" });
  };

  const onShowContextualMenu = React.useCallback(
    (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault(); // don't navigate
      setShowContextualMenu(true);
    },
    []
  );

  const onHideContextualMenu = React.useCallback(
    () => setShowContextualMenu(false),
    []
  );

  const onClearAllChatHistory = async () => {
    setClearing(true);
    let response = await historyDeleteAll();
    if (!response.ok) {
      setClearingError(true);
    } else {
      appStateContext?.dispatch({ type: "DELETE_CHAT_HISTORY" });
      toggleClearAllDialog();
    }
    setClearing(false);
  };

  const onHideClearAllDialog = () => {
    toggleClearAllDialog();
    setTimeout(() => {
      setClearingError(false);
    }, 2000);
  };

  React.useEffect(() => {}, [
    appStateContext?.state.chatHistory,
    clearingError,
  ]);

  return (
    <section
      style={{ padding: "5px" }}
      className={
        appStateContext?.state.activeStack === "history"
          ? styles.containerSmallScreen
          : styles.container
      }
      data-is-scrollable
      aria-label={"chat history panel"}
    >
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        wrap
        aria-label="chat history header"
      >
        {appStateContext?.state.isSmallScreen && (
          <StackItem>
            <button
              className="btn backIcon"
              onClick={() => {
                appStateContext?.dispatch({
                  type: "SET_ACTIVE_STACK",
                  payload: "chat",
                });
              }}
            >
              <BackIcon></BackIcon>
            </button>
          </StackItem>
        )}

        <StackItem>
          <Text
            role="heading"
            aria-level={2}
            style={{
              alignSelf: "center",
              fontWeight: "600",
              fontSize: "18px",
              marginRight: "auto",
              paddingLeft: "20px",
            }}
          >
            Conversations
          </Text>
        </StackItem>
        {chatHistory && chatHistory.length > 0 ? (
          <Stack verticalAlign="start">
            <Stack
              horizontal
              styles={commandBarButtonStyle}
              className={styles.alignbtns}
            >
              <CommandBarButton
                className="bgTransparent"
                iconProps={{ iconName: "More" }}
                title={"Clear all chat history"}
                onClick={onShowContextualMenu}
                aria-label={"clear all chat history"}
                styles={commandBarStyle}
                id="moreButton"
              />
              <ContextualMenu
                items={menuItems}
                hidden={!showContextualMenu}
                target={"#moreButton"}
                onItemClick={toggleClearAllDialog}
                onDismiss={onHideContextualMenu}
                styles={{
                  root: {
                    color: "var(--secondary)",
                  },
                }}
              />
              {!appStateContext?.state.isSmallScreen &&
                appStateContext?.state.activeStack === "all" && (
                  <StackItem>
                    <button
                      title="Hide History"
                      className="btn backIcon"
                      onClick={() => {
                        appStateContext?.dispatch({
                          type: "SET_ACTIVE_STACK",
                          payload: "allButHistory",
                        });
                      }}
                    >
                      <SideBarClose></SideBarClose>
                    </button>
                  </StackItem>
                )}
              {/* <CommandBarButton
              className="bgTransparent"
              iconProps={{ iconName: "Cancel" }}
              title={"Hide"}
              onClick={handleHistoryClick}
              aria-label={"hide button"}
              styles={commandBarStyle}
            /> */}
            </Stack>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>

      <Stack
        aria-label="chat history panel content"
        styles={{
          root: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            paddingTop: "2.5px",
            maxWidth: "100%",
          },
        }}
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          flexWrap: "wrap",
          padding: "1px",
          overflow: "hidden",
        }}
      >
        <Stack className={styles.chatHistoryListContainer}>
          {appStateContext?.state.chatHistoryLoadingState ===
            ChatHistoryLoadingState.Success &&
            appStateContext?.state.isCosmosDBAvailable.cosmosDB && (
              <ChatHistoryList setDefaultQues={setDefaultQues} />
            )}
          {appStateContext?.state.chatHistoryLoadingState ===
            ChatHistoryLoadingState.Fail &&
            appStateContext?.state.isCosmosDBAvailable && (
              <Stack>
                <Stack
                  horizontalAlign="center"
                  verticalAlign="center"
                  style={{ width: "100%", marginTop: 10 }}
                >
                  <StackItem>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "400",
                        fontSize: 16,
                      }}
                    >
                      {appStateContext?.state.isCosmosDBAvailable?.status && (
                        <span>
                          {appStateContext?.state.isCosmosDBAvailable?.status}
                        </span>
                      )}
                      {!appStateContext?.state.isCosmosDBAvailable?.status && (
                        <span>Error loading chat history</span>
                      )}
                    </Text>
                  </StackItem>
                  <StackItem>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "400",
                        fontSize: 14,
                      }}
                    >
                      <span>Chat history can't be saved at this time</span>
                    </Text>
                  </StackItem>
                </Stack>
              </Stack>
            )}
          {appStateContext?.state.chatHistoryLoadingState ===
            ChatHistoryLoadingState.Loading && (
            <Stack>
              <Stack
                horizontal
                horizontalAlign="center"
                verticalAlign="center"
                style={{ width: "100%", marginTop: 10 }}
              >
                <StackItem
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Spinner
                    style={{
                      alignSelf: "flex-start",
                      height: "100%",
                      marginRight: "5px",
                    }}
                    size={SpinnerSize.medium}
                  />
                </StackItem>
                <StackItem>
                  <Text
                    style={{
                      alignSelf: "center",
                      fontWeight: "400",
                      fontSize: 14,
                    }}
                  >
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      Loading chat history
                    </span>
                  </Text>
                </StackItem>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Stack
        aria-label="New Conversation"
        styles={{
          root: {
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            paddingTop: "2.5px",
            maxWidth: "100%",
            justifyContent: "flex-end",
          },
        }}
        style={{
          padding: "10px 20px",
          height: "auto",
          display: "flex",
          flexGrow: 1,
          flexFlow: "column wrap",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CommandBarButton
          role="button"
          styles={{
            icon: {
              color: "var(--default)",
            },
            iconDisabled: {
              cursor: "not-allowed",
              color: "var(--secondary)",
              opacity: "30%",
            },
            root: {
              color: "var(--default)",
              backgroundColor: "var(--secondary)",
            },
            rootDisabled: {
              backgroundColor: "var(--secondary)",
              color: "var(--default)",
              cursor: "not-allowed",
              opacity: "30%",
            },
            rootHovered: {
              backgroundColor: "var(--secondary)",
              color: "var(--default)",
            },
            iconHovered: {
              color: "var(--default)",
            },
          }}
          className={styles.newChatIcon}
          iconProps={{ iconName: "Add" }}
          text={"New Conversation"}
          onClick={newChat}
          disabled={disabledButton()}
          aria-label="Start a new chat button"
          title={"Start new chat"}
        />
      </Stack>
      <Dialog
        hidden={hideClearAllDialog}
        onDismiss={clearing ? () => {} : onHideClearAllDialog}
        dialogContentProps={clearAllDialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          {!clearingError && (
            <PrimaryButton
              onClick={onClearAllChatHistory}
              disabled={clearing}
              styles={{
                icon: {
                  color: "var(--default)",
                },
                iconDisabled: {
                  cursor: "not-allowed",
                  color: "var(--default)",
                },
                root: {
                  color: "var(--default)",
                  backgroundColor: "var(--secondary)",
                },
                rootDisabled: {
                  backgroundColor: "var(--disabled)",
                  color: "var(--default)",
                  cursor: "not-allowed",
                },
                rootHovered: {
                  backgroundColor: "var(--secondary)",
                  color: "var(--default)",
                },
                iconHovered: {
                  color: "var(--default)",
                },
              }}
              text="Clear All"
            />
          )}
          <DefaultButton
            onClick={onHideClearAllDialog}
            disabled={clearing}
            styles={{
              icon: {
                color: "var(--default)",
              },
              iconDisabled: {
                cursor: "not-allowed",
                color: "var(--default)",
              },
              root: {
                color: "var(--default)",
                backgroundColor: "var(--error)",
              },
              rootDisabled: {
                backgroundColor: "var(--disabled)",
                color: "var(--default)",
                cursor: "not-allowed",
              },
              rootHovered: {
                backgroundColor: "var(--error)",
                color: "var(--default)",
              },
              iconHovered: {
                color: "var(--default)",
              },
            }}
            text={!clearingError ? "Cancel" : "Close"}
          />
        </DialogFooter>
      </Dialog>
    </section>
  );
}
