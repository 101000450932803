import React, { useState } from "react";
import Upload from "../../../components/CWS/Upload/Upload";
import styles from "./Cws.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";
import avatar from "../../../assets/android CarbonAI.svg";
const Cws: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Upload");
  const appStateContext = React.useContext(AppStateContext);
  return (
    <div className={styles.main}>
      <ul className={styles.tabList}>
        {/* <li
          className={`${styles.tabListItem} ${
            activeTab === "Upload" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("Upload")}
        > */}
        <li
          className={`${styles.tabListItem} ${
            activeTab === "Upload" ? styles.activeTab : ""
          }`}
        >
          <img
            src={avatar}
            alt="avatar"
            className="avatar"
            style={{ width: "auto" }}
          />
          Self Upload
        </li>
        {/* <li
          className={`${styles.tabListItem} ${
            activeTab === "Other" ? styles.activeTab : ""
          }`}
          onClick={() => setActiveTab("Other")}
        >
          Other Tab
        </li> */}
      </ul>
      <div
        className={
          appStateContext?.state.isSmallScreen
            ? styles.tabContentSmallScreen
            : styles.tabContent
        }
      >
        {activeTab === "Upload" && <Upload />}
        {activeTab === "Other" && <div>Content for other tab</div>}
      </div>
    </div>
  );
};

export default Cws;
