import { useContext } from "react";
import Logo from "../../../assets/logo.svg";
import styles from "../../CSS/Layout.module.css";
import { AppStateContext } from "../../../state/CarbonAIChat/AppProvider";

export default function Header() {
  const isReportingHub = sessionStorage.getItem("isReportingHub") === "true";
  const appStateContext = useContext(AppStateContext);
  return (
    <header
      className={
        appStateContext?.state.activeStack === "history" ||
        appStateContext?.state.activeStack === "chat"
          ? `borderBottom bgColorDefault ${styles.headerSmallScreen}`
          : `borderBottom bgColorDefault`
      }
      role={"banner"}
    >
      <div
        className={
          appStateContext?.state.activeStack === "all"
            ? `col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12`
            : `col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6`
        }
      >
        {!isReportingHub && (
          <img
            alt="logo"
            src={Logo}
            className={`${styles.headerIcon}`}
            aria-hidden="true"
          />
        )}

        <span>
          &nbsp; &nbsp;
          {!isReportingHub && <b>CarbonAI</b>}
        </span>
      </div>

      {appStateContext?.state.isSmallScreen &&
        appStateContext?.state.activeStack === "chat" && (
          <div
            className={
              !isReportingHub
                ? `col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 ${styles.flexFull}`
                : `col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12  ${styles.flexFull}`
            }
          >
            <button
              className={`btn btn-primary  ${styles.floatRight}`}
              onClick={() => {
                appStateContext?.dispatch({
                  type: "SET_ACTIVE_STACK",
                  payload: "history",
                });
              }}
            >
              Show History
            </button>
          </div>
        )}
      {appStateContext?.state.isSmallScreen &&
        appStateContext?.state.activeStack === "history" && (
          <div
            className={
              !isReportingHub
                ? `col-lg-6 col-md-6 col-sm-6 col-xs-6 col-xl-6 ${styles.flexFull}`
                : `col-lg-12 col-md-12 col-sm-12 col-xs-12 col-xl-12 ${styles.flexFull}`
            }
          >
            <button
              className={`btn btn-primary  ${styles.floatRight}`}
              onClick={() => {
                appStateContext?.dispatch({
                  type: "SET_ACTIVE_STACK",
                  payload: "chat",
                });
              }}
            >
              Hide History
            </button>
          </div>
        )}
    </header>
  );
}
