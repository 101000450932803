import React, { useState, CSSProperties } from "react";
import { Icon, IIconStyles } from "@fluentui/react/lib/Icon";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import back from "../../../assets/back.svg";
// Initialize Fluent UI icons
initializeIcons();

// Styles for the Stop icon
const stopIconStyles: IIconStyles = {
  root: {
    fontSize: 20, // Adjust the size as needed
    color: "var(--secondary)", // Icon color
    display: "inline-block",
  },
};

export const CitationLink = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="blue"
      className="bi bi-box-arrow-up-right"
      viewBox="0 0 20 20"
    >
      <path
        fill-rule="evenodd"
        d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
      />
      <path
        fill-rule="evenodd"
        d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
      />
    </svg>
  </span>
);
export const SideBarOpen = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--secondary)"
      className="bi bi-layout-sidebar-inset-reverse"
      viewBox="0 0 16 16"
    >
      <path d="M2 2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
      <path d="M13 4a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1z" />
    </svg>
  </span>
);
export const SideBarClose = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--secondary)"
      className="bi bi-layout-sidebar-inset"
      viewBox="0 0 16 16"
    >
      <path d="M14 2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1zM2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2z" />
      <path d="M3 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1z" />
    </svg>
  </span>
);
export const SendIcons = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="var(--secondary)"
      className="bi bi-send-fill sendEnable"
      viewBox="0 0 20 20"
    >
      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
    </svg>
    {/* <Icon iconName="Send" styles={sendIconStyles} /> */}
  </span>
);
export const SendIconsDisabled = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="var(--secondary)"
      className="bi bi-send-fill sendDisable"
      viewBox="0 0 20 20"
    >
      <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
    </svg>
  </span>
);
export const StopIcons = () => (
  <div>
    <Icon iconName="StopSolid" styles={stopIconStyles} />
  </div>
);
interface Props {
  color: string;
}
export const DoneIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="var(--default)"
        className="bi bi-check-lg"
        viewBox="0 0 16 16"
      >
        <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425z" />
      </svg>
    </span>
  );
};
export const CancelIcon = () => {
  return (
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="var(--error)"
        className="bi bi-x-lg"
        viewBox="0 0 16 16"
      >
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
      </svg>
    </span>
  );
};
export const EditIcon = () => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="var(--default)"
      viewBox="0 0 16 16"
    >
      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
      <path
        fill-rule="evenodd"
        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
      />
    </svg>
  </span>
);

interface RegenerateProps {
  color: string;
}
interface Props {
  color: string;
  answerText: string;
}

export const CopyIcon = ({ color, answerText }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Copied to clipboard:", textToCopy);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Hide the success message after 2 seconds
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
      copyToClipboardFallback(textToCopy);
      setIsCopied(true);
    }
  };

  const copyToClipboardFallback = (text: string) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textarea);
  };

  const containerStyle: CSSProperties = {
    position: "relative", // Establishes a positioning context for the absolute elements inside
    display: "inline-block", // Keeps the icon inline with text
  };

  const messageStyle: CSSProperties = {
    position: "absolute", // Position the message absolutely
    left: "0", // Align the left edge of the message with the left edge of the icon
    top: "100%", // Position the message below the icon
    backgroundColor: "green", // Add a background color for visibility
    color: "white", // Text color
    padding: "2px 6px", // Some padding around the text
    borderRadius: "4px", // Rounded corners for the tooltip box
    marginTop: "4px", // Add space between the icon and the message
    whiteSpace: "nowrap", // Prevent the message from wrapping
    zIndex: "10", // Ensure the tooltip is above other content
  };

  return (
    <div style={containerStyle} onClick={() => copyToClipboard(answerText)}>
      <span
        style={{
          cursor: "pointer",
          display: "inline-block",
          paddingLeft: "2px",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="21"
          fill={color === "secondary" ? "var(--secondary)" : "var(--sub)"}
          className="bi bi-copy"
          viewBox="0 0 20 20"
        >
          <title>Copy</title>
          <path
            fill-rule="evenodd"
            d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
          />
        </svg>
      </span>
      {isCopied && <div style={messageStyle}>Copied!</div>}
    </div>
  );
};

export const ReGenIcon = ({ color }: RegenerateProps) => (
  <span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={color === "secondary" ? "var(--secondary)" : "var(--sub)"}
      className="bi bi-arrow-clockwise"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"
      />
      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
    </svg>
  </span>
);
export const BackIcon = () => (
  <span>
    <img src={back} alt="backBtn" className="backBtn" />
  </span>
);
